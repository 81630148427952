@import "partial/variable";
@import "bootstrap/bootstrap.scss";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@font-face {
  font-family: "gt_walsheim_promedium";
  src: url("../fonts/gt-walsheim-pro-medium-webfont.woff2") format("woff2"),
    url("../fonts/gt-walsheim-pro-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gt_walsheim_proregular";
  src: url("../fonts/gt-walsheim-pro-regular_0-webfont.woff2") format("woff2"),
    url("../fonts/gt-walsheim-pro-regular_0-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gt_walsheim_proultra_bold";
  src: url("../fonts/gt-walsheim-pro-ultra-bold-webfont.woff2") format("woff2"),
    url("../fonts/gt-walsheim-pro-ultra-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
  padding: 0;
}

body {
  background-color: #f8f8f8;
  height: 100%;
  color: $font-color;
  font-family: "gt_walsheim_proregular";
  font-size: 14px;
  overflow: hidden;
  // overflow-y: auto;
}

a {
  display: block;
  text-decoration: none !important;
  color: #404f57;
}

dl,
ol,
ul {
  padding: 0;
}

ol {
  font-size: 16px;
}

/* scroll width */
::-webkit-scrollbar {
  width: 0.3125rem;
  cursor: pointer;
}

/* Scroll handle */
::-webkit-scrollbar-thumb {
  background: rgba(203, 212, 234, 1);
  border-radius: 0.3125rem;
  cursor: pointer;
}

.bgcolorwhit {
  background: $heading-color;
  height: calc(100% - 105px);
}

.wosheading {
  height: calc(100% - 64px);
}

.home-icon {
  /*position: absolute;*/
  left: 17px;
  /*top: 15px;*/
  display: block;
}

.logout-icon {
  position: absolute;
  color: white;
  // background-color: hotpink;
  top: 2px;
  // right: 25px;
  display: block;
}

header {
  display: block;
  height: 63px !important;
  //background-image: url(../header.png);
  background-position: left center;
  background-size: 100% 100%;
  background-color: #0b6eb6;
}

.notFound {
  background-image: url(../errorImage.png);
  height: 100% !important;
  background-size: 40%;
  background-position: center Top;
  margin-top: 8%;
  background-repeat: no-repeat, repeat;

  @media screen and (max-width: $tablet-width) {
    margin-top: 50%;
    background-size: 100%;
  }
}

.errorName {
  height: 5%;
  padding-top: 22%;

  @media screen and (max-width: $tablet-width) {
    padding-top: 60%;
  }
}

.errorButton {
  width: 20%;
  background-color: blue;
  box-shadow: none;

  @media screen and (max-width: $tablet-width) {
    width: 60%;
  }
}

.errorButton:hover {
  background-color: blue;
}

.errorButton:active {
  background-color: blue;
}

footer {
  // display: flex;
  // justify-content: center;
  background-color: snow;
  position: fixed;
  height: 50px !important;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;

  @media screen and (max-width: $tablet-width) {
    height: 40px !important;
    z-index: 2;
  }

  .main-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    gap: 12px;

    @media screen and (max-width: $tablet-width) {
      font-size: 10px;
    }

    margin: 0;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.35rem 0.45rem;
  margin-bottom: 6px;
  list-style: none;
  border-radius: 0.25rem;
  // margin-left: -30px;
  margin-top: -17px;

  @media screen and (max-width: $tablet-width) {
    padding: 0.55rem 0.45rem;
    margin-left: 0;
    margin-top: -21px;
  }
}

.sliderstart .slick-next.slick-disabled:before {
  opacity: 0.25;
  cursor: default;
}

.sliderstart .slick-prev.slick-disabled:before {
  opacity: 0.25;
  cursor: default;
}

.sliderstart .slick-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sliderstart .slick-list::-webkit-scrollbar:vertical {
  width: 5px;
}

.sliderstart .slick-list::-webkit-scrollbar:horizontal {
  height: 5px;
}

@-moz-document url-prefix() {
  .sliderstart .slick-list {
    scrollbar-width: thin;
  }
}

.sliderstart .slidersection {
  width: 100%;
}

.sliderstart {
  .slidersection {
    .txt-center {
      text-align: left;
      padding: 0 40px;

      p {
        padding: 0px 20px 0px 20px;
      }
    }

    .key-text,
    .name-last-flashcard {
      p {
        padding: 0px 20px 0px 20px;
      }
    }
  }
}

.sliderstart .slick-list {
  overflow: hidden;
  overflow-y: auto;
  height: 430px;
  width: 100%;
  transition: all 400ms ease;
  scroll-behavior: smooth;

  @media screen and (max-width: $mobile-width) {
    width: auto;
    transition: all 400ms ease;
    scroll-behavior: smooth;
  }
}

.sliderstart .slick-list:hover {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.main-container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;

  .intro-section {
    display: flex;
    margin-bottom: 1rem;
    padding-right: 5px;
  }

  .card-body {
    @media screen and (max-width: $midum-width) {
      min-height: 122px;
    }

    @media screen and (max-width: $mobile-width) {
      min-height: auto;
    }
  }

  .skillname {
    color: $font-color;
    letter-spacing: 2px;
    font-size: 20px;
  }

  h2.page-title {
    margin: 0;
    font-size: 18px;
    color: $font-color;
  }

  .page-head h2 {
    font-size: 18px;
    color: $font-color;
    float: right;

    @media screen and (max-width: $tablet-width) {
      font-size: 14px;
    }
  }

  .card {
    min-height: 140px;
    margin-bottom: 20px;
    border: 1px solid #d1d0d0;
    box-shadow: 0px 3px 6px #312f2f29;
    padding: 5px;

    .card-img {
      width: 80px;
      height: auto;
      padding: 0;

      @media screen and (max-width: $mobile-width) {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100%;
      }
    }

    .card-img.parpal,
    .card-img.red,
    .card-img.blue {
      img {
        width: 65%;
      }
    }

    .card-img.yellow {
      img {
        width: 85%;
      }
    }

    .card-txt {
      display: flex;
      flex-direction: column;
      align-self: center;
      height: 100%;
      justify-content: center;

      img {
        margin-right: 10px;
      }

      div {
        display: flex;
        color: $font-light-color;

        span {
          font-size: 13px;
        }
      }

      h3 {
        font-size: 20px;
        color: $font-color;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;

        // margin-bottom: 15px;
        // text-transform: capitalize;
        @media screen and (max-width: $tablet-width) {
          margin-bottom: 10px;
        }
      }
    }

    .small-prog {
      position: relative;
      height: 100%;
    //  left: 85px;
      top: 5px;
      min-width: 120px;
      .progress {
        // position: absolute;
        width: 100%;
        height: 5px;
        bottom: 30px;
      }

      .progress-bar {
        background-color: #546e7a;
      }
    }
  }

  .lessoncard {
    border: none;
    box-shadow: none;
  }

  .my-nugget {
    min-height: 140px;
  }
}

h2.page-heading {
  text-align: center;
  font-size: 20px;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 20px;
  padding-bottom: 0px;
 // background-color: #5f606140;

  @media screen and (max-width: $tablet-width) {
    font-size: 14px;
    // text-align: left;
    padding: 12px;
  }
}

.que-section {
  max-width: 570px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
 // box-shadow: 5px 5px 5px 0 rgba(202, 203, 212, 0.14);
  top: 0;
  background: #fff;
  padding: 20px;
  clear: both;
  display: block;

  @media screen and (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 18px;
    padding-top: 0px;
    margin-bottom: 30px;
    font-weight: 600;

    // text-transform: capitalize;
    @media screen and (max-width: $tablet-width) {
      text-align: left;
      padding-top: 23px;
      font-size: 16px;
      margin-bottom: 23px;
    }
  }

  ol {
    padding-left: 58px;
    border-bottom: $border-bottom;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: $tablet-width) {
    font-size: 14px;
    margin-bottom: 15px;
  }

  input {
    // position: absolute;
    // opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: -58px;
    height: 22px;
    width: 22px;
    border: 1px solid #e3e3e3;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 8px;
    height: 13px;
    border: solid #546e7a;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkbox-block {
  @media screen and (max-width: $mobile-width) {
    flex: 1;
  }
}

.font-bold {
  font-weight: bold;
}

.container2 {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: $tablet-width) {
    font-size: 14px;
    margin-bottom: 15px;
  }

  input {
    // position: absolute;
    // opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: -58px;
    height: 22px;
    width: 22px;
    border: 1px solid #e3e3e3;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 8px;
    height: 13px;
    border: solid #546e7a;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/*css by shital*/

.logo-mobile-image {
  height: 45px;
  width: 58px;
}

.skip-quiz {
  text-align: left;
  max-width: 150px;
}

.user-circle {
  content: "\f2bd";
  color: #fff;
  margin-right: 5px;
  margin-left: 5px;
}

.searchbar {
  flex-flow: row;
}

.user-nameinfo {
  margin-top: 4px;
}

.userDropdown.btn-secondary {
  padding: 5px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  //margin-top: 6px;
}

.learnerDropdown {
  width: 117px;
  margin-left: 3px;
  border: 1px solid;
}

.showdrop {
  left: 4px !important;
  border-top: none !important;
  width: 122px;
  min-width: 0 !important;
  padding: 8px;
}

.adminoption {
  padding: 0;
}

.learnerDropdown.show .btn-secondary.dropdown-toggle {
  background-color: #f1f3f2;
  color: #000000;
}

.learnerDropdown.show .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.learnerDropdown.show .btn-secondary:not(:disabled):not(.disabled):active {
  border: none;
  background-color: #f1f3f2;
  color: #000000;
}

.userDropdown.dropdown-toggle::after {
  vertical-align: middle;
}

.userDetails {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.userprofile-mob {
  width: 500px;
  height: 500px;
  margin: 75px auto;
  text-align: center;
  background: #ffffff;

  @media screen and (max-width: $tablet-width) {
    margin-top: 0;
    padding-top: 15px;
    width: 350px;
    margin-bottom: auto;
    background: #ffffff;
    position: relative;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }
}

.user-img {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 26px;
}

.user-img.first {
  background-image: url("http://empowercx.sitel.com/wp-content/uploads/2017/10/speaker3-min.jpg");
}

.username {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.position {
  display: block;
  margin-bottom: 20px;
}

.usertable .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
}

.usertable tr {
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 40px;
}

.iconSpan {
  margin-left: 20px;
}

.userText {
  margin-left: 20px;
}

.usericons {
  font-size: 24px;
}

.pencilicon {
  font-size: 19px;
  color: #fff;
  background-color: #5ed800;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  position: relative;
  top: -121px;
  left: 61px;
  text-align: center;
  line-height: 35px;
  vertical-align: middle;

  @media screen and (max-width: $tablet-width) {
    font-size: 19px;
    color: #fff;
    background-color: #5ed800;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 29%;
    left: 59%;
    text-align: center;
    line-height: 35px;
    vertical-align: middle;
  }
}

.edit-icon {
  font-size: 19px;
  color: #ccc;
  background-color: #fff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  position: relative;
  top: -8px;
  left: 218px;
  text-align: center;
  line-height: 35px;
  vertical-align: middle;

  @media screen and (max-width: $tablet-width) {
    font-size: 19px;
    color: #ccc;
    background-color: #fff;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    position: relative;
    top: 23px;
    left: 149px;
    text-align: center;
    line-height: 35px;
    vertical-align: middle;
  }
}

.person-data {
  margin-top: -32px;

  @media screen and (max-width: $tablet-width) {
    margin-top: -13px;
  }
}

/*slider css*/

.buzzes-longSlider {
  overflow: auto;
  margin-top: -10px;
  margin-bottom: 0.65rem;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: rgb(12, 12, 12);

  @media screen and (max-width: $tablet-width) {
    overflow: auto !important;
    margin-top: 5px;
    margin-bottom: 0.65rem;
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #0c0c0c;
  }
}

.quiz-buzzes {
  overflow: auto;
  margin-top: -7px;
  // margin-bottom: 0.65rem;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: rgb(12, 12, 12);

  @media screen and (max-width: $tablet-width) {
    overflow: auto !important;
    margin-top: 0;
    margin-bottom: 0.65rem;
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #0c0c0c;
  }
}

.page-head {
 // width: 21%;
  display: inline-block;
  justify-content: center;

  @media screen and (max-width: $tablet-width) {
    width: 250px;
  }
}

.name-last-flashcard {
  padding: 40px 20px;
  font-size: 18px;
  margin: 15px auto;
}

.breadMain {
  a {
    display: inline;
  }

  span {
    font-size: 16px;
    color: #546e7a;
    margin-left: 5px;
  }
}

.flashcard-last-longSlider {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img-last-flashcard,
  .name-last-flashcard {
    flex: 1;
  }
}

.sliderMain {
  text-align: center;
}

.sliderstart .slick-list .slidepagestext {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 42px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background-color: green;
}

.sliderstart .slick-next:before {
  font-size: 25px;
  line-height: 1;
  opacity: 0.75;
  color: #444aa0;
}

.sliderstart .slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: 0.75;
  color: #444aa0;
}

.basic-content-wrapper {
 // height: calc(100vh - 10px);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 926px) {
    padding: 0px 10px 55px;
  }
  @media screen and (max-width: $tablet-width) {
    padding: 0px 10px 90px;
  }
}

.sliderstart {
  background-color: #fff;
 // width: 600px;
  height: 600px;
  margin: 15px auto;
  text-align: center;
  padding: 10px;
  box-shadow: 4px 4px #dee2e6;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    height: 480px;
    margin-bottom: 0;
    margin-bottom: 5px;
  }
}

.slidecards {
  position: relative;
 // padding: 10px 0;
}

.slidecards--animatable {
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;

  .slidecards {
    * {
      user-select: none;
    }
  }
}

.showkeytextmain {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  width: 88%;
  margin: auto;
  display: flex;
  padding: 1.5rem 0;
  height: 400px;

  .key-text {
    width: 100%;
    margin: 0;
    padding: 0 !important;
  }
}

.txt-center {
  display: flex;
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.img-center-longSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 15px;

  img {
    height: auto;
    width: 280px;

    @media screen and (max-width: $mobile-width) {
      width: 100%;
    }
  }
}

.key-link {
  a[href] {
    color: blue;
  }

  a:hover {
    color: purple;
  }

  text-decoration: underline;
}

.key-text {
  text-align: left;
  margin-top: 19px;
  padding: 0 21px 0 20px;
  font-size: 18px;
  font-weight: 400;

  ol {
    margin-left: 20px;
  }
}

p {
  margin-bottom: 5px;
  // text-align: left;
  padding: 0px;
}

.evaluationIMG {
  max-width: 109px;
  width: auto;

  @media screen and (max-width: $mobile-width) {
    width: auto;
    max-width: 150px;
    margin-bottom: 5px;
    height: auto;
  }
}

.submit-btn {
  margin: 10px 0;
}

.slick-slide img {
  display: inline;
}

.sliderstart .key-link {
  height: 260px;
  max-height: 260px;
  margin-top: 25px;
}

.sliderstart .slick-next {
  font-size: 0;
  line-height: 0;
  top: 50%;
  right: -8px;
  width: 52px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 2;

  @media screen and (max-width: $mobile-width) {
    top: 50%;
    right: -20px;
    display: none !important;
  }
}

.sliderstart .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  left: -8px;
  width: 52px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 2;

  @media screen and (max-width: $mobile-width) {
    top: 50%;
    left: -20px;
    display: none !important;
  }
}

.key-image {
  width: 250px;
  margin: auto;
}

.key-image a img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.sliderstart .key-text {
  text-align: left;
  margin-top: 19px;
  padding: 0 21px 0 20px;
  font-size: 18px;
  font-weight: 400;

  ol {
    margin-left: 10%;
  }

  ul {
    margin-left: 10%;
  }

  h1 {
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 5px;
    padding: 0px 21px 0px 21px;
  }
}

/*second slider component css*/

// .secondsliderwrapper-long {
//   position: relative;
//   height: 100vh;
//   max-height: 520px;
//   //overflow: hidden;
//   //overflow-y: auto;

//   @media screen and (max-width: $mobile-width) {
//     width: 100%;
//     position: relative;
//     height: 100vh;
//     max-height: 438px;
//     overflow: hidden;
//   }
// }

.outerContent-longSlider .progress {
  height: 5px;
  border-radius: 20px;
  max-width: 50%;
  margin: 0 auto;
  margin-top: -16px;
  margin-bottom: 20px;
 // color: #645df3;
}

.background-img-longSlider {
  background-size: cover;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 460px;
  overflow: hidden;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.10196078431372549);
  background-position-y: center !important;
}

.background-img-longSlider .inner-img-longSlider {
  width: 100%;
  height: 100%;
  background-position-y: center !important;
  transition: all 1s;
}

.hero-slider-long {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  max-height: 460px;
  overflow: hidden;
  position: relative;
}

.hero-slider-long .slick-prev,
.hero-slider-long .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 47%;
  display: block;
  width: 90px !important;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 1;
}

.hero-slider-long .slick-next:before {
  content: "\f105";
  font: 40px/1 "FontAwesome", Arial, sans-serif;
  font-size: 50px;
  color: #3d4042;
  font-weight: 800;
}

.hero-slider-long .slick-prev:before {
  content: "\f104";
  font: 40px/1 "FontAwesome", Arial, sans-serif;
  color: #3d4042;
  font-size: 50px;
  font-weight: 800;
}

.hero-slider-long .slick-disabled:before {
  opacity: 0.25;
  cursor: default;
}

.hero-slider-long .slick-disabled:before {
  opacity: 0.25;
  cursor: default;
}

.secondslidertxt-center {
  font-size: 16px;
  font-weight: 500;
}

.secondSlidername-last-flashcard {
  padding: 20px 0;
  font-size: 16px;
  margin: auto;
  text-align: center;
}

.secondSlidername-last-flashcard p {
  text-align: center;
}

.secondSlidekey-text-longSlider {
  padding: 0;
  font-size: 16px;
  font-weight: 400;

  ol {
    margin-left: 20px;
  }
}

.secondSlideflashcard-last {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .img-last-flashcard,
  .name-last-flashcard {
    flex: 1;
  }
}

.hero-slider-long .slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero-slider-long .magic-dots.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 1;
}

.onlytextdiv .secondSlidekey-text-longSlider {
  display: flex;
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.textandimagecontainer-longSlider .secondSlidekey-text-longSlider {
  width: 50%;
  padding: 50px;
}

.theme_Preview {
  text-align: center;
  height: 100%;
  width: 40px;
}

.themePreview {
  text-align: center;
  height: 40px;
  width: 40px;
}

.themePreview img {
  width: 100%;
  height: 100%;
}

.custom_model {
  top: 15%;
  right: 0;

  .modal-content {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    /* text-shadow: 0 1px 0 #fff; */
    opacity: 0.5;
    width: 17px;
    padding: 0;
    margin: 0;
    z-index: 1111;
  }

  .modal-header {
    border: none;
  }

  .modalThemeImg {
    width: 100%;
    height: 90%;
  }
}

.custom_model_body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.textandimagecontainer-longSlider .img-center-longSlider {
  width: 50%;
  float: right;
  height: 400px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 30px;

    img {
      width: 85%;
      height: auto;
    }
  }
}

// .evaluationslide {
//   margin-top: 80px;
// }

.secondslideonlyimage .img-center-longSlider {
  margin-top: 10px;
}

.secondslideonlyimage {
  .img-center {
    height: 100% !important;
    width: 100% !important;
    padding: 0% !important;
    // max-height: 485px !important;

    img {
      // height: 100% !important;
      // width: 100% !important
      // padding: 10px;
      max-height: inherit !important;
    }
  }
}

.key-link-video {
  height: 100%;
  width: 100%;
  max-height: inherit !important;

  iframe {
    max-height: inherit !important;
    border: none;
  }
}

.key_text_video {
  .key-link {
    .react-player {
      height: 60vh !important;
      border: none;
    }
  }
}

.aftertipimage {
  width: 100%;
}

.quizviews::-webkit-scrollbar {
  -webkit-appearance: none;
}

.quizviews::-webkit-scrollbar:vertical {
  width: 7px;
}

// .quizviews::-webkit-scrollbar:horizontal {
//     height: 12px;
// }
.quizviews::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  // border: 2px solid #ffffff;
}

.questionAlign {
  display: flex;
}


.radio-block {
  .container1 {
    display: block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: $tablet-width) {
      font-size: 14px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: $mobile-width) {
      flex: 1;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      background-color: #fff;
      @include border-radius(50%);
      position: absolute;
      top: 1px;
      left: -58px;
      height: 25px;
      width: 25px;
      border: 1px solid #a5acb2;
    }

    :hover input~.checkmark {
      background-color: #ccc;
    }

    input:checked~.checkmark {
      background-color: transparent;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      @include border-radius(50%);
      background: #37474f;
    }
  }

  .container2 {
    display: block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: $tablet-width) {
      font-size: 14px;
      margin-bottom: 15px;
      padding-left: 15px;
    }

    input {
      // position: absolute;
      // opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      left: -58px;
      height: 22px;
      width: 22px;
      background-color: #fff;
      border: 1px solid #a5acb2;

      @media screen and (max-width: $tablet-width) {
        left: -28px;
      }

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 7px;
      top: 2px;
      width: 8px;
      height: 13px;
      border: solid #546e7a;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.ans-block {
  @media screen and (max-width: $mobile-width) {
    flex: 1;
  }
}

.right-ans {
  img {
    margin-bottom: 8px;
  }

  span {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .alert-success {
    background-color: #e0f8ed;
    border-color: transparent;
    @include border-radius(0);
    color: $green-color;
    margin-top: 15px;
    font-size: 18px;
    padding: 12px;
    text-align: center;
  }
}

.notification-text {
  a {
    display: inline;
    text-decoration: underline !important;
    color: blue;
  }
}

.wrong-ans {
  img {
    margin-bottom: 8px;
    width: 20px;
  }

  span {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;

    @media screen and (max-width: $tablet-width) {
      font-size: 16px;
    }
  }

  .alert-danger {
    color: #ea5555;
    background-color: #ffeded;
    border-color: transparent;
    @include border-radius(0);
    margin: 5px 0 10px;
    font-size: 14px;
    text-align: center;
  }
}

.correct-block {
  .correct-txt {
    font-weight: 600;
    font-size: 18px;
    margin-top: 18px;
  }

  .correct-ans {
    color: $green-color;
    font-size: 18px;
    margin-top: 10px;

    @media screen and (max-width: $tablet-width) {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}

.next-btn {
  display: inline;
  margin: 10px 0;
}

.submit-btn {
  text-align: center;
  margin: 10px 0;

  button {
    height: 60px;
    width: 200px;
    @include border-radius(40px);
    color: $heading-color;
    background-color: #d9d9d9;
    border-color: #d9d9d9;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      color: $heading-color;
      background-color: #d9d9d9;
      border-color: #d9d9d9;
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}  

.flex-child:first-child {
  margin-right: 20px;
} 
.btn-success.active {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  height: 50px;
  width: 150px;
}

.btn-success-next.active {
  color: #fff;
  background-color: transparent;
  border-color: 2px solid #1e7e34;
  height: 50px;
  width: 150px;
}

.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  height: 50px;
  width: 150px;
  cursor: auto;
  &:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
}

a.skip-btn {
  display: block;
  margin-top: 10px;
  color: #a6b4ba;
  font-size: 17px;

  &:hover {
    color: #666;
    font-weight: bold;
    text-decoration: none;
  }
}

.next-btn {
  button.btn {
    float: right;
    @include border-radius(0);
    display: inline-block;
    padding: 0.25em 0.2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    width: 120px;
    height: 44px;
  }

  button:not(:disabled):not(.disabled):active,
  button:not(:disabled):not(.disabled):active:focus {
    background: transparent;
  }
}

section.review-section {
  max-width: 450px;
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 5px 0 rgba(202, 203, 212, 0.14);
  top: 0;
  background: #fff;
  padding: 20px;
  clear: both;
  display: block;
  margin: 15px auto;

  .btn-success {
    background-color: $green-color;
  }

  .review-txt {
    text-align: center;
    font-size: 18px;
    font-weight: 500;

    @media screen and (max-width: $mobile-width) {
      flex: 1;
      font-size: 14px;
    }

    h2 {
      color: $green-color;
      font-size: 20px;
      margin: 40px 0 15px;
      font-weight: 600;

      @media screen and (max-width: $tablet-width) {
        font-size: 18px;
        margin-top: 15px;
      }
    }
  }

  .success-msg {
    padding: 0 0 19px;
    border-bottom: $border-bottom;
    font-size: $heading-font-size;
    font-weight: 500;
  }

  .rate-exp {
    margin: 25px 0;
    font-size: 18px;
    font-weight: 600;
  }

  .rate-img {
    margin-bottom: 35px;

    img {
      margin-left: 10px;
    }
  }
}

.add-comment {
  text-align: left;

  .form-group {
    span {
      color: #c5cacc;
    }
  }
}

.form-control {
  color: $font-color;
  min-height: 89px;

  &:focus {
    color: $font-color;
    border-color: #ced4da;
    box-shadow: none;
  }
}

section {
  &.flash-card-block {
    //max-width: 675px;
    //margin: 0 auto 0;
   // padding-top: 25px;

    @media screen and (max-width: $tablet-width) {
      margin: -17px auto 0;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll !important;
      // max-width: 72%;
    }

    .progress {
      height: 5px;
      border-radius: 20px;
      max-width: 50%;
      // margin-top: -10px;
      margin: 0 auto;
      margin-top: -16px;

      @media screen and (max-width: $tablet-width) {
        max-width: 72%;
        margin-top: -15px;
      }
    }

    .flashcard {
      width: 500px;
      height: 500px;
      margin: 15px auto;
      text-align: center;

      @media screen and (max-width: $mobile-width) {
        width: 100%;
        margin-bottom: 0;
      }

      .stackedcards {
        position: relative;
      }

      .stackedcards--animatable {
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;

        .stackedcards {
          * {
            user-select: none;
          }
        }

        .stackedcards-container {
          .card-item {
            h1 {
              font-size: 20px;
              font-weight: 600;
            }

            .txt-center {
              display: flex;
              align-items: left;
              padding: 0 21px 0 20px;
              height: 100%;
            }

            .img-center {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                height: auto;
                width: 280px;

                @media screen and (max-width: $mobile-width) {
                  width: 100%;
                }
              }
            }

            .key-link {
              a[href] {
                color: blue;
              }

              a:hover {
                color: purple;
              }

              text-decoration: underline;
            }

            .key-text {
              text-align: left;
              margin-top: -18px;
              padding: 0 21px 0 20px;

              ol {
                margin-left: 20px;
              }
            }

            p {
              margin-bottom: 5px;
              text-align: left;
              align-self: center;
              padding: 0 21px 0 20px;
            }

            .evaluationIMG {
              max-width: 200px;
              width: auto;

              @media screen and (max-width: $mobile-width) {
                width: auto;
                max-width: 150px;
                margin-bottom: 5px;
                height: auto;
              }
            }

            .submit-btn {
              margin: 10px 0;
            }
          }

          >*,
          .stackedcards-overlay:last-child {
            box-shadow: 3px 3px 3px 0 rgba(148, 149, 152, 0.14);
            position: absolute;
            width: 100%;
            height: 450px;

            @media screen and (max-width: $midum-width) {
              // height: 750px;
            }

            @media screen and (max-width: $mobile-width) {
              height: 100%;
            }

            will-change: transform,
            opacity;
            bottom: 0;
            border-radius: 0;
            background: #fff;
            padding: 40px 20px;
            top: 0;
            overflow-y: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 18px;

            @media screen and (max-width: $tablet-width) {
              // box-shadow:5px 5px 5px 0 rgba(2, 8, 66, 0.14);
            }

            >div {
              height: 100%;
              width: 100%;
            }

            &:nth-child(1) {
              position: relative;
              display: block;
              padding: 40px 20px;
            }

            h5 {
              font-size: 24px;
              line-height: 40px;

              @media screen and (max-width: $tablet-width) {
                font-size: 18px;
                line-height: 32px;
              }
            }
          }

          @media screen and (max-width: $mobile-width) {
            height: calc(100vh - 206px);
          }

          .stackedcards-origin-bottom {
            transform-origin: bottom;
          }
        }
      }

      .stackedcards-overlay {
        position: absolute;
        width: 100%;
        height: 450px;
        will-change: transform, opacity;
        bottom: 0;
        border-radius: 0;

        &.top,
        &.left,
        &.right {
          align-items: center;
          display: flex;
          justify-content: center;
          left: 0;
          opacity: 0;
          bottom: 0;
          height: 100%;
          font-size: 24px;
          // text-transform: uppercase;
          font-weight: 500;
          color: #fff;
        }
      }

      .global-actions {
        position: relative;
        width: 100%;
        top: -262px;

        @media screen and (max-width: $mobile-width) {
          margin-top: 20px;
        }

        .left-action,
        .right-action {
          width: 28px;
          height: 28px;
          background-image: url(/img/back-arrow.svg);
          background-repeat: no-repeat;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          // text-transform: uppercase;
          font-weight: 500;
          color: #fff;
          float: left;
          background-color: #676565;
          border-radius: 50%;

          img.d-none-desktop {
            display: none;

            @media screen and (max-width: $tablet-width) {
              display: block;
            }
          }

          .d-none-mob {
            @media screen and (max-width: $tablet-width) {
              display: none;
            }
          }
        }

        .right-action {
          float: right;
          transform: scaleX(-1);
        }
      }

      .flashcard-last-longSlider {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img-last-flashcard,
        .name-last-flashcard {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .mob-container {
    padding: 0;

    h2.page-title {
      margin: 15px 10px;
    }

    .row {
      margin: 0;

      .col-12.col-sm-12.col-md-6.col-lg-6.col-xl-6 {
        padding: 0;
      }

      .card {
        margin-bottom: 0;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0;

        .card-body {
          padding: 20px 0;
        }

        .card-txt {
          h3 {
            font-size: $heading-font-mob-size;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .flashcard-mob {
    background: #f1f5fd;
    margin-top: 0;
    padding-top: 15px;
    height: 100%;
  }
}

div#root {
  height: 100%;
}

.main-wrapper {
  height: 100%;
}

.login-signup-wrapper {
  height: 100%;
}

.app {
  height: 100%;
}

div#root {

  &>div.app,
  &>div.page-container {
    height: calc(100% - 0);
  }

  .height-100 {
    height: 100% !important;
  }

  .height-100.quest-wrapper,
  .quest-wrapper {
    // height: calc(100% - 175px)!important;
    // overflow-y: scroll;
  }
}

.page-container.quest-wrapper {
  // height: calc(100% - 175px)!important;
  // overflow-y: scroll;
}

// .quizMain.quest-wrapper{
//    height: calc(100% - 175px)!important;
//     overflow-y: scroll;
// }
.right-action.disabled {
  opacity: 0.2;
}

.left-action.disabled {
  opacity: 0.2;
}

.que-block {
  @media screen and (max-width: $tablet-width) {
    height: calc(100% - 115px);
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.rating-block {
  @media screen and (max-width: $tablet-width) {
    height: calc(100% - 90px);
  }

  .card {
    margin-bottom: 50px;
  }
}

.landing_img {
  height: 100%;
  width: 100%;
}

.harbinger_logo {
  height: auto !important;
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.logo_img {
  height: auto !important;
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.landing_link {
  position: relative !important;
  text-align: center !important;
  width: 100%;
  margin-top: 11%;
  font-size: 25px;
  color: blue;
}

@media screen and (max-width: 768px) {
  .que-block {
    height: auto !important;
  }

  div#root .height-100.quest-wrapper {
    height: 100% !important;
    margin-bottom: 45px;
    overflow-x: hidden;
  }
}

/*search functionality start*/

.search-box1 {
  float: left;
  width: 100%;

  // margin-top: 2px;
  // @media (min-width: 786px) and (max-width: 1399px){
  //   width: 77%;
  // }
  @media screen and (max-width: 991px) {
    width: 65%;
    margin-left: 35%;
  }
}

.search-box {
  // width: 50%;
  margin-top: -4px;
  margin-bottom: 7px;
  padding-right: 10px;
}

.has-search {
  .form-control {
    padding-left: 2.45rem;
    border-radius: 28px;
    width: 100%;
    height: 50px !important;
    min-height: 0;
  }

  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.45rem;
    height: 2.375rem;
    line-height: 3.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

.categorySelect {
  // background: transparent linear-gradient(281deg, #3a3985 0%, #3499ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 28px;
  padding: 10px 15px;
  // color: #fff;
  margin-left: 20px;
  height: 50px !important;
  width: 170px;
  margin-top: -4px;

  @media screen and (max-width: 991px) {
    width: 68%;
    margin-left: 35%;
    margin-bottom: 10px;
  }
}

.form-search-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 35px;
  padding-top: 7px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-search-input {
  width: 100%;
  background-color: #fff;
  color: black;
  outline: none;
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 17px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem !important;
  padding: 5px 10px 5px 35px;
}

.justify-content {
  justify-content: flex-end;
}

.skill_desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.showmore {
  padding-left: 10px;
  color: #1717f4;
  cursor: pointer;
  text-decoration: underline;
}

/*search functionality end*/

.footer-logo-image {
  height: 40px;
  padding-right: 6px;
  // width: 40px;
}

.footer-text {
  // width: 100%;
  font-size: 10px;
  display: block;
  margin-right: 2%;
  text-align: center;
  line-height: 45px;
}

.footer-img-align {
  margin-left: 1%;
  line-height: 45px;

  @media screen and (max-width: $tablet-width) {
    line-height: 0px;
  }
}

.nudge_image {
  height: 50px;
  width: 50px;
  border-radius: 3rem;
}

.nudge_icon {
  filter: grayscale(100%);
  width: 20px;
  height: 20px;
}

.video-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21em;
  padding-top: 15px;
}

.hideInMobile {
  @media screen and (max-width: $tablet-width) {
    display: none;
  }
}

// css for notification icon
.notification-icon {
  width: 42px;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;

  .bell-icon {
    font-size: 20px;
    color: #00b27c !important;
  }

  .notification-count {
    left: 10px;
    bottom: 18px;
    font: 0.875rem "72", Helvetica, Arial, sans-serif;
  }
}

.notification-popover {
  .popover {
    top: 22px;
  }

  .popover-title {
    word-break: break-word;
    font-weight: bold;
    margin-right: 5px;
    text-shadow: white 0 1px 0;
  }

  .popover-body {
    max-height: 400px !important;
    overflow-y: auto !important;
    min-width: 274px !important;
    @media screen and (max-width: 1025px) {
      max-height: 800px !important;
    }
    @media screen and (max-width: 1025px) and (orientation: landscape) {
      max-height: auto !important;
    }
    @media screen and (max-width: 800px) and (orientation: landscape) {
      max-height: auto !important;
    }
  }

  .span-remove {
    cursor: pointer;
  }

  .top-space {
    top: 0px !important;
  }
}

/*Leaner Dasboard scss*/

.main-wrapper {
  // padding-left: 15px;
  // padding-right: 15px;
}

.content-wrapper {
  margin-left: 200px;
  -webkit-transition: all 0.5s;
  transition: all 1s;
  margin-top: 85px;

  @media screen and (max-width: 1025px) {
    // margin-left: 0;
  }
}

.content-wrapper-lb {
  margin: 0 0;
  // @media screen and (max-width: 768px) {
  //   margin: 20% 0;
  // }
  // @media screen and (max-width: 425px) {
  //   margin: 35% 0;
  // }
  // @media screen and (max-width: $tablet-width) {
  //   margin: 20% 0;
  // }
  // @media screen and (max-width: $mobile-width) {
  //   margin: 20% 0;
  // }
}
.SignUpErrors {
  width: 70%;
}

.sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 80px;
  -webkit-transition: all 1s;
  transition: all 1s;

  @media screen and (max-width: 1025px) {
    left: 0;
    z-index: 100;
    width: 200px;
  }

  .logo-wrapper {
    background: transparent linear-gradient(278deg, #3a3985 0%, #3499ff 100%) 0% 0% no-repeat padding-box;
    line-height: 80px;
    text-align: center;
    overflow: hidden;
  }

  .nav-panel {
    margin-top: 35px;

    .navbar {
      display: block;
      padding: 0;
    }

    .nav-active {
      // background: #f8f8f8;
      background: #64646426;
      color: #3499ff;
    }

    ul {
      padding: 0px;
      margin: 0px;
    }

    li {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: hidden;
      height: auto;
      line-height: 25px;
      margin-bottom: 5px;
    }

    .nav-icon {
      //   width: 25px;
      //   height: 25px;
      float: left;
      fill: #9c9c9c;
      margin-top: 1px;
      margin-left: 10px;
    }

    .nav-link {
      font-size: 16px;
      color: #222;
      margin-top: 0px;
      display: flex;

      .nav-label {
        display: block;
        float: left;
        padding: 0px 0px 0px 15px;
      }
    }
  }

  .sidebar .nav-panel .nav-link:hover {
    color: #3499ff;
  }

  .sidebar .nav-panel .nav-link:hover .nav-icon {
    fill: #3499ff;
  }

  .left-panel {
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px #00000014;
    box-shadow: 0px 0px 10px #00000014;
    height: 100vh;
  }

  .menu-icon {
    background: #d1cece;
    border-bottom-left-radius: 10px;
    width: 44px;
    line-height: 40px;
    height: 44px;
    float: right;
    text-align: center;
  }

  .dropdown-menu.inner {
    font-family: "Font Awesome 5 Free", Arial, sans-serif;
  }

  .dropdown-menu.show {
    border-radius: 15px;
    // padding: 10px;
    /* height: 45px; */
    padding: 3px 6px;
    background-color: #fff;
    margin: 1px 0 0;
    font-size: 14px;
    left: 6px !important;
    min-width: 9rem;
  }

  .profile-wrapper {
    text-align: center;
    padding-top: 50px;

    .profile-show {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      text-align: center;
      background: #d1cece;
      margin: auto;
    }

    .profile-name {
      // font-family: "Montserrat";
      font-size: 14px;
      color: #000;
      font-weight: bold;
      padding-top: 10px;
      text-transform: uppercase;
    }
  }
}

.closesidebar {
  width: 70px;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 80px;
  -webkit-transition: all 1s;
  transition: all 1s;

  @media screen and (max-width: 1025px) {
    // left: -300px;
    z-index: 100;
  }

  .profile-wrapper img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
  }

  .menu-icon {
    background: #d1cece;
    border-bottom-left-radius: 10px;
    width: 44px;
    line-height: 40px;
    height: 44px;
    float: right;
    text-align: center;
  }

  .left-panel {
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px #00000014;
    box-shadow: 0px 0px 10px #00000014;
    height: 100vh;
  }

  .nav-panel .nav-icon {
    margin: 0 auto;
  }

  .usernamedisplay {
    width: 50px !important;
    height: 50px !important;

    span {
      font-size: 18px !important;
    }
  }
}

.select-role {
  padding-top: 10px;

  .btn {
    border-radius: 15px;
    background: #f2f2f2;
    height: 40px;
    border: none;
    color: #646464;
    width: 135px;
  }

  .dropdown-toggle::after {
    margin-left: 0.455em;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    background-color: #f2f2f2;
    color: #646464;
    font-size: 14px;
  }
}

.adminbtn .dropdown-menu {
  min-width: 7rem;
}

.select-role .bootstrap-select .btn {
  border-radius: 10px;
  background: #f2f2f2;
  height: 35px;
  border: none;
}

.select-role select.selectpicker {
  display: block;
  border-radius: 15px;
  /* Prevent FOUC */
}

.closediv {
  display: none !important;
}

/*header classes*/

.header-reducer {
  margin-top: 15px;
  margin-left: 120px;
  -webkit-transition: all 1s;
  transition: all 1s;
  width: 100%;
}

.setting-popover {
  .popovermenu {
    display: block;
    font-size: 15px;
    margin-bottom: 1px;
    padding: 3px;
    cursor: pointer;
  }

  .setting-popover-body {
    width: 180px !important;
  }

  a:hover {
    background-color: #e9ecef;
    color: #000;
  }
}

.header-wrapper {
  height: 80px;
  width: 100%;
  // background: transparent linear-gradient(272deg, #3A3985 0%, #3499FF 100%) 0% 0% no-repeat padding-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .headerBlock {
    display: flex;
    align-items: center;
    height: 80px;
    border: 2px solid #EAECF6;
    .logo-wrapper {
      flex: 1;
      float: none;
    }
  }

  @media screen and (max-width: 1025px) {
    margin-top: 0;
    margin-left: 0;

    .mobilesearchbar {
      margin-top: 117px;
    }

    .mobileheader {
      //   padding: 12px 15px;
      //   //  background: transparent linear-gradient(281deg, #3a3985 0%, #3499ff 100%) 0% 0% no-repeat padding-box;
      //   position: fixed;
      //   z-index: 100;
    }

    .mobile-menu-icon {
      display: block;
      padding: 10px 4px;
    }

    .contact-admin-mobile {
      display: block;

      span {
        display: none;
      }
    }

    .mobile-menu-logo {
      display: block;
    }

    .order1 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }

    .header-wrapper .categoryselect {
      margin-left: 17px;
    }
  }

  .contact-admin {
    font-size: 15px;
    color: #646464;
    float: left;
    border-right: 1px solid #d4d4d4;
    padding-right: 23px;
    margin-top: 16px;

    img {
      margin-right: 10px;
    }

    span {
      display: block;
      margin-top: -18px;
      margin-left: 21px;
    }
  }

  .white-bg {
    background: #fff;
    //-webkit-box-shadow: 0px 6px 18px #00000012;
    //box-shadow: 0px 6px 18px #00000012;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    padding: 16px;
    float: left;
    margin-left: 10px;
    position: relative;

    @media screen and (max-width: 550px) {
      width: 30px;
      height: 30px;
      padding: 7px;
    }
  }

  .bell-count {
    background: #00b27c;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    font-size: 12px;
    color: #fff;
  }

  .icon-section {
    @media screen and (max-width: 1025px) {
      margin-top: -2px;
    }
  }

  .logo-wrapper {
    line-height: 80px;
    margin-left: 20px;
    float: left;

    img {
      @media screen and (max-width: 550px) {
        width: 155px;
      }

      @media screen and (max-width: 400px) {
        width: 134px;
      }
    }

    .menu-icon {
      float: left;
      margin-right: 10px;

      img {
        @media screen and (max-width: 550px) {
          width: auto;
        }
      }
    }
  }

  .webheader {
    margin-top: 12px;
    margin-right: 10px;

    @media screen and (max-width: 1025px) {
      margin-top: 5px;
     // width: 100%;
    }
  }
}

.password_model {
  .modal-header .close {
    width: 50px;
    outline: none;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .form-control {
    min-height: 0;
    width: 350px;

    @media screen and (max-width: 1025px) {
      width: 316px;
    }
  }

  .modal-content {
    width: 600px;

    @media screen and (max-width: 1025px) {
      width: 350px;
    }
  }

  .modal-header {
    background-color: #e9ecef;
  }

  .modal-footer {
    background-color: #e9ecef;

    @media screen and (max-width: 1025px) {
      justify-content: center;
    }
  }

  .contactBtn {
    width: 130px;
  }

  .col-form-label {
    font-size: 16px;
  }
}

.header-wrapper .contact-admin img {
  margin-right: 10px;
}

.contact_model {
  top: 100px;
  padding: 8px;

  .modal-header .close {
    width: 50px;
    outline: none;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .form-control {
    min-height: 0;
    width: 350px;

    @media screen and (max-width: 1025px) {
      width: 315px;
    }
  }

  .modal-content {
    width: 600px;

    @media screen and (max-width: 1025px) {
      width: auto;
    }
  }

  .modal-header {
    background-color: #e9ecef;
  }

  .modal-footer {
    background-color: #e9ecef;
  }

  .contactBtn {
    width: 130px;
  }

  .col-form-label {
    font-size: 16px;
  }
}

/* Dashboard Scss*/

.margin-reduce {
  margin-left: 70px;
  -webkit-transition: all 0.5s;
  transition: all 1s;
  margin-top: 85px;

  @media screen and (max-width: 1025px) {
    // margin-left: 0;
  }
}

.lesson-margin-reduce {
  -webkit-transition: all 0.5s;
  transition: all 1s;
  // @media screen and (max-width: 1025px) {
  //   // margin-left: 0;
  // }
  // @media screen and (max-width: 768px) {
  //   margin: 20% 0;
  // }
  // @media screen and (max-width: 425px) {
  //   margin: 35% 0;
  // }
  // @media screen and (max-width: $tablet-width) {
  //   margin: 20% 0;
  // }
  // @media screen and (max-width: $mobile-width) {
  //   margin: 20% 0;
  // }
}

.courses-wrapper {
  //  background: #fff;
  // box-shadow: 0px 6px 18px #312f2f29;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  /* position: absolute; */
  padding: 30px 15px;
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 480px) {
    padding: 15px;
    overflow: scroll !important;
  }

  .nugget-status-wrapper {
    border-radius: 10px;
    // border: 1px solid #DEDEDE;
    height: 90%;
    padding: 40px;
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 6px #312f2f29;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 25px;
      height: 125px;
      overflow: scroll !important;
    }

    .status-circle {
      font-size: 50px;
      // float: left;
      padding: 0 20px;

      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
    }

    .assigned-bg {
      color: #00c168;
    }

    .completed-bg {
      color: #8176b8;
    }

    .in-progress-bg {
      color: #ff6d8a;
    }

    .status-text {
      color: #646464;
      font-size: 15px;
      margin-top: 3px;
      text-align: left;
      display: inline-block;

      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }

    .nuggets_status {
      // margin-top: 30px;
    }

    .marbot20 {
      margin-bottom: 20px;
    }

    .quiz-status {
      margin: 0 auto;
      width: 55%;
    }
  }

  .section-title {
    font-size: 25px;
    color: #222;
    padding-bottom: 20px;
  }

  .video-wrapper {
    border-radius: 10px;
    height: 300px;
    width: 520px !important;
  }

  .react-player {
    height: 300px !important;
    width: 100% !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .video-time {
    float: right;
    background: #384fa1;
    width: 116px;
    height: 64px;
    border-top-left-radius: 33px;
    border-bottom-right-radius: 10px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    font-size: 14px;
    margin-top: 159px;

    .fa-clock {
      padding-left: 5px;
    }
  }

  .nuggets-section {
    margin-top: 0px;

    .nav-link {
      // padding-left: 0;
      color: #646464;
      font-size: 15px;
      border: none;
      display: block;

      // border-radius: 0.25rem;
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }

    .nav-item {
      .nuggets_tab_active {
        color: #3499ff;

        border-bottom: 2px solid #3499ff !important;
      }
    }

    .nav-tabs {
      border: none;
      padding-left: 16px;
    }

    .slick-list {
      margin-left: 10px;
    }

    .slick-next:before {
      content: "\f105";
      font: 40px/1 "FontAwesome", Arial, sans-serif;
      font-size: 35px;
      color: #00b27c;
      font-weight: 600;
    }

    .slick-prev:before {
      content: "\f104";
      font: 40px/1 "FontAwesome", Arial, sans-serif;
      color: #00b27c;
      font-size: 35px;
      font-weight: 600;
    }

    .slick-next {
      top: 38%;
      right: -6px;

      @media screen and (max-width: 480px) {
        display: none !important;
      }
    }

    .slick-prev {
      top: 38%;
      left: -13px;

      @media screen and (max-width: 480px) {
        display: none !important;
      }
    }

    .tab-content {
      padding: 7px;
    }

    .slick-dots {
      @media screen and (max-width: 480px) {
        bottom: 0;
      }
    }
  }

  .recent-nuggets {
    border-radius: 10px;
    border: 1px solid #e6e3e3;
    box-shadow: 0px 3px 6px #312f2f29;
    padding: 15px;
    height: 125px;
    max-height: 125px;
    overflow: hidden;
    margin-right: 19px;
    background: #fff;

    @media screen and (max-width: 480px) {
      margin-right: 2px;
      margin-bottom: 15px;
    }

    &:hover {
      overflow-y: auto;
    }

    img {
      width: 50px;
      height: 50px;
      float: left;
      margin-top: 3px;
    }

    .nuggets-title {
      font-size: 15px;
      color: #222;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    .nugget-desc {
      margin-left: 60px;
      position: relative;
      /* width: 210px;
        height: 115px;*/
      overflow-wrap: break-word;
    }

    .viewed_by {
      margin-bottom: 10px;
      margin-top: -7px;
    }

    .nuggets-text {
      color: #222;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    .new_flag {
      position: relative;
      float: right;
      margin-top: -18px;
      margin-right: -15px;
    }

    .categoryPrivate {
      position: relative;
      float: right;
      margin-top: -15px;
      margin-right: -11px;
    }
  }

  .lock-red {
    font-size: 30px;
    color: #fd3434c2 !important;
    margin-top: 4px;
    margin-right: 4px;
  }

  .lock-green {
    font-size: 30px;
    color: #28a745 !important;
    margin-top: 7px;
    margin-right: 6px;
  }

  .nuggets-img {
    float: left;
  }

  .nuggets-icon {
    width: 50px;
    height: 50px;
  }
}

.report-margin {
  margin-top: 9%;
}

.request_model {
  top: 150px;

  .close {
    width: 50px;
    outline: none;

    &:hover {
      background: none !important;
    }
  }

  .modal-header {
    padding: 0.65rem;
  }
}

.inner-courses-wrapper {
  background: #fff;
  box-shadow: 0px 6px 18px #312f2f29;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  /* position: absolute; */
  padding: 30px 15px;
  height: calc(100vh - 130px);
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    padding: 15px;
  }
}

.report-table-wrapper {
 height: calc(100vh - 150px);
 overflow: scroll;
 -webkit-overflow-scrolling: touch;
//  @media screen and (max-width: 480px) {
//   height: calc(100vh - 100px);
// }
}

.slick-dots {
  bottom: 0px;
}

.pending_nugget-status-wrapper {
  padding: 20px 12px;
  border-radius: 10px;
  // border: 1px solid #DEDEDE;
  background: #fff;
  box-shadow: 0px 3px 6px #312f2f29;
  height: 90%;
  position: relative;

  .quiz-title {
    color: #212529d1;
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 400;
    padding-left: 10px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .skill_image {
    float: left;

    img {
      height: 50px;
      width: 50px;
      border-radius: 3rem;
      margin-top: 6px;
    }
  }

  .pending_details_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: flex-start;
    padding: 14px;
    height: 150px;
    // width: 440px;
    // border: 1px solid #d1d0d0;
    // box-shadow: 0px 3px 6px #312f2f29;
    margin: auto;

    .learn_title {
      text-align: center;
      background: #64646426;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 35px;
      font-size: 16px;
      /* padding-top: 3px; */
      margin: auto;
      margin-top: 33px;
    }

    .slick-next:before {
      font-size: 21px;
      color: #00b27c;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .slick-prev:before {
      font-size: 21px;
      color: #00b27c;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .slick-prev {
      left: -22px;
      top: 45%;
    }

    .slick-next {
      right: -22px;
      top: 45%;
    }
  }

  .userpendingskillcard {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 13px;
    padding-top: 5px;
  }

  .progress {
    height: 0.45rem;
    margin-top: 9px;
    margin-left: 68px;
    width: 60%;
    @media screen and (max-width: 768px) and (orientation: portrait) {
      width: 35%;
    }
  }

  .viewSkillName {
    color: #646464;
    position: relative;
    font-size: 15px;
    margin-left: 71px;
    margin-top: 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .completion_ratio {
    float: right;
    margin-top: -16px;
    margin-right: -5px;
    font-size: 15px;
    font-weight: 600;
  }

  .slick-dots {
    bottom: 0;
  }
}

.video-slider-wrapper {
  .slick-slider {
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
  }

  .slick-list {
    position: relative;
  }
}

.custom_cursor {
  cursor: pointer;
}

/* User Reports css*/
.report-search-box {
  margin-top: -4px;
  margin-bottom: 7px;
  padding-right: 10px;
  width: 262px;

  @media screen and (max-width: 768px) {
    width: 200px;
    margin-bottom: 0;
  }

  .form-search-input {
    border-radius: 20px !important;
    padding: 8px 10px 8px 35px;
  }
}

.reports_table {
  @media screen and (max-width: 768px) {
    .rdt_TableHead {
      display: none;
    }

    .rdt_TableRow,
    .rdt_TableRows {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.725em;
      padding: 25px;
    }

    .rdt_TableCell {
      border-bottom: 1px solid #ddd;
      display: contents;
      font-size: 13px;
      width: 100%;
      text-align: right;
      height: 166px;
    }

    .rdt_TableCell:first-child::before {
      content: "Sr";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(2)::before {
      content: "Nugget";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(3)::before {
      content: "Learning bite";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(4)::before {
      content: "Status";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(5)::before {
      content: "Completion Date";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(6)::before {
      content: "Score";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:nth-child(7)::before {
      content: "Quiz Result";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }

    .rdt_TableCell:last-child::before {
      content: "Time Spent";
      float: left;
      font-weight: bold;
      color: #2e316f;
      padding-left: 0;
      font-size: 13px;
    }
  }
}

.sumbit-answer {
  width: 100px;
}

.dashboard-border {
  border-right: 2px #dee2e7 solid;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.text-center {
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.noResults {
  font-size: 17px;
  margin: auto !important;
  padding: 24px;
}

.passingScore {
  font-size: 20px;
}

.Toastify__close-button {
  width: 10%;
  padding: 5px 5px !important;
}

.display{
  display: flex;
  justify-content: center;
}

/* Changes Piyush Saini */
@media screen and (max-width: 820px) {
  .page-head {
    width: 250px;
    margin-top: 16px;
  }

  .header-wrapper {
    .logo-wrapper {
      line-height: 0;

      & div:first-of-type:not(.menu-icon) {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .mob-container {
    .search-box {
      width: 66%;
    }

    .categorySelect {
      width: 100%;
      background-position: right 20px center !important;
    }

    .row {
      .card {
        margin: 10px;

        .card-body {
          padding: 0;
        }
      }
    }
  }

  .sidebar {
    &~footer {
      margin-left: 4rem;
      transition: margin-left 0.5s linear;
    }

    &.closesidebar {
      &~footer {
        margin-left: 0;
        transition: margin-left 0.5s linear;
      }
    }
  }

  footer {
    height: 50px !important;
    line-height: 50px;
  }

  .contact_model_body {

    & select,
    & textarea {
      width: 90% !important;
    }
  }
  .notification-popover .popover-body{
    max-height: 550px !important;
  }
}

@media screen and (max-width: 600px) {
  .mob-container {
    .search-box {
      width: 100%;
      padding-right: 0;
    }
  }

  .content-wrapper {
    margin-left: 0;
  }

  .closesidebar {
   // overflow-y: scroll;
    &~footer {
      margin-left: 30px !important;

      & .main-heading {
        gap: 0px;
      }

      & .footer-logo-image {
        width: 92px;
      }

      & .footer-text {
        font-size: 7px;
      }
    }
  }

  .courses-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 115px);
    & .nugget-status-wrapper {
      padding: 6px 25px;
      height: 240px;

      & .row.nuggets_status {
        &>* {
          border-right: none;
          border-bottom: 2px #dee2e7 solid;
          padding-bottom: 6px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    & .nuggets-section {
      margin-bottom: 28px;

      & ul {
        &>li {
          flex: 1 1 auto;
          text-align: center;
        }
      }
    }
  }
}

.crud-btn .btn-secondary {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  font-size: 20px;
  width: 40%;
  padding-left: 0%;
}

.crud-btn .btn-secondary:focus,
.crud-btn .btn-secondary:hover {
  color: #3cd0d9;
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  width: 40%
}

.profile-container {
  border-radius: 4px;
  background: #fff;
  border: 1px solid #DDDDDF;
  width: 80%;
  margin: 0 auto;

  //margin-top: 80px;
  @media screen and (max-width: 768px) {
    border: none;
    width: 100%;
    border-radius: 0;
    margin-top: 80px;
    height: 100vh;
  }

  .profile-left {
    padding: 15px 10px;
    text-align: center;

    .emp-img {
      @media screen and (max-width: 768px) {
        padding: 15px 10px;
        margin-top: -80px;
      }

      img {
        border-radius: 50%;
        width: 130px;
        height: 130px;
      }

      .sb-avatar {
        border-radius: 50%;
      }
    }

    .emp-name {
      font-size: 20px;
      color: #222D56;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .profile-right {
    border-left: 1px solid #E2E2E3;
    padding: 15px 30px;

    .label-text {
      // font-size: 14px;
      color: #707070;
    }

    .value-text {
      // font-size: 14px;
      color: #707070;
      overflow: hidden;
      word-break: break-all;
      white-space: "nowrap";
    }

    .border-line {
      border-bottom: 1px dashed #dddddf;
      padding: 12px 0;
    }
  }
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-size: small;
  color: #6d7173;
  padding-left: 20px;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
/* box for title text */
[data-title]:after {
  content: attr(data-title);
  /* position for title view box */
  position: absolute;
  bottom: -1em;  	
  left: 35%;
  z-index: 100;
  visibility: hidden;
  background-color: #ffffff;
  color: #111;
  font-size: 11px;
  padding: 1px 5px 2px 5px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
}
[data-title] {
  position: relative;
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(35, 35, 35, 0.5);
}
.spinner {
  margin: 250px auto;
  height: 60px;
  width: 60px;
  animation: spin 2s linear infinite;
-webkit-animation: spin 2s linear infinite; /* Safari */
  border: 3px solid#3499ff;
  border-right-color: #f3f3f3;
  border-radius: 50%;
  color:white;
}

.alignCenter{
  margin: -318px auto;
  text-decoration-color: white;
  height: 50%;
  color:white;
  width: 3%;
}
.quiz-wrapper{
  height: calc(100vh - 10px)!important;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;


  @media screen and (max-width: 768px){
  // height: 100% !important;
  margin-bottom: 45px;
  overflow-x: hidden;
  padding-top: 10px;
  // background: url('./quizgameImg/background.png');

}
}
.circulerPrgressbar {
  height: 70px;
  width: 70px;
  cursor: pointer;
  display: inline-block;

  img{
   width: 30px;
   margin-left: 5px;
  }  
}
@media only screen and (max-width: 915px) and (max-height: 450px) and (orientation: landscape) {
  // .card {
  //   & .small-prog {
  //     left: 100px !important;
  //   }
  // }
  .circulerPrgressbar{
    height: 40px;
    width: 40px;
    img{
      width: 15px;
    }
  }
  .courses-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 115px);
  }
}