@media screen and (max-width: 768px) {
  .main-div {
    &:hover {
      box-shadow: #12121357 1px 1px 1px 1px;
      border-radius: 0.25rem;
    }
    .card {
      margin-bottom: 0 !important;
      border-radius: 0.25rem !important;
      //box-shadow: rgba(0, 0, 0, 0.45) 1px 1px 2px !important;
      border: 1px solid #d6d6d6 !important;
    }
  }
}
.uname-circle {
  margin-top: 63px;
}
.main-div {
  .custom-card {
    margin-bottom: 0px;
    min-height: 8rem;
    background-color: #ffffff;
    border-radius: 0.25rem;
    //box-shadow: rgba(0, 0, 0, 0.45) 1px 1px 2px;
    border: 1px solid #d6d6d6;
    .nudge_image {
      border-radius: 0;
    }
  }
}
.main-div:hover {
  box-shadow: #12121357 1px 1px 1px 1px;
  border-radius: 0.25rem;
}

.outlook-content-wrapper {
  margin: 10 0;
  @media screen and (max-width: 768px) {
    margin: 20% 0;
  }
  @media screen and (max-width: 425px) {
    margin: 35% 0;
  }
}

.outlook-header-div {
  .outlook-header {
    height: 45px !important;
    background-color: #fff !important;
    margin-bottom: 20px;
  }
}

.page-container.quest-wrapper.outlook-quest-wrapper {
  height: 100%;
  max-height: 550px;
  // overflow-y: scroll;
  margin-top: -137px;
  @media screen and (max-width: 768px) {
    height: 100%;
    max-height: 600px;
  }
}

.scroll-container {
  overflow-y: scroll;
  height: 310px;
}

.scroll-container-inner {
  overflow-y: scroll;
  height: 480px;
}

.feedback-div {
  .heading {
    font-family: "Segoe UI Semibold", Arial, sans-serif;
    color: #016677;
    font-size: 17px;
  }
  .card-title {
    font-family: "Segoe UI Semibold", Arial, sans-serif;
    color: #4a4a4a;
    font-size: 15px;
  }

  .justifyContent {
    justify-content: center;
  }

  .text-font {
    font-size: 17px;
  }

  .text-padding {
    padding-left: 10px;
  }

  .fa-font {
    font-size: 20px;
    cursor: pointer;
    color: #016677;
  }

  .line-height {
    line-height: 40px;
  }

  .custom-check-box {
    width: 20px;
    height: 20px;
    margin-top: 10px;
  }

  .checkbox-label {
    font-size: 1em;
    font-weight: 500;
    color: #707070;
    margin-top: 10px;
  }

  .feedback-btn {
    color: #fff;
    background-color: #016677;
    border-color: #016677;
    width: auto;
  }
}

.date-font {
  font-size: 14px !important;
  font-weight: bold;
  color: #000000cf;
}

.header-text-font {
  color: #7c0092;
}
.outlook-page-title {
  font-size: 18px;
  color: #37474f;
  margin-top: 20px;
  margin-left: 10px;
  @media screen and (max-width: 480px) {
    margin-top: 80px;
    margin-right: 10px;
    margin-left: 12px;
  }
}

.outlook-courses-wrapper {
  //background: #fff;
  //box-shadow: 0px 6px 18px #312f2f29;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  /* position: absolute; */
  padding: 30px 15px;
  //   height: calc(100vh - 185px);
  //   overflow:hidden;
  //   overflow-y:auto;
  text-align: center;

  @media screen and (max-width: 480px) {
    padding: 12px;

    margin-top: 30px;
  }

  .outlook-nugget-status-wrapper {
    border-radius: 10px;
    border: 1px solid #dedede;
    height: 100%;
    padding: 40px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 12px 17px;
      background: #fff;
      margin-top: 41px;
    }
  }

  .status-circle {
    font-size: 50px;
    float: left;
    padding: 0 20px;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      width: 50px;
      height: 50px;

      padding: 0;
    }
  }

  .outlook-assigned-bg {
    color: #00c168;
    &:after {
      content: "";
      background-color: #dee2e6;
      position: absolute;
      width: 2px;
      height: 55px;
      top: 22px;
      left: 93%;
      display: block;
    }
  }
  .outlook-completed-bg {
    color: #8176b8;
    &:after {
      content: "";
      background-color: #dee2e6;
      position: absolute;
      width: 2px;
      height: 55px;
      top: 22px;
      left: 115px;
      display: block;
      @media screen and (max-width: 768px) {
        left: 93px;
      }
    }
  }
  .outlook-in-progress-bg {
    color: #ff6d8a;
  }
  .outlook-status-text {
    color: #646464;
    font-size: 18px;
    margin-top: 3px;
    text-align: left;
    display: inline-block;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .outlook-quiz-title {
    color: #3499ff;
    font-size: 20px;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}
.outllook_pending_nugget-status-wrapper {
  padding: 20px 12px;
  border-radius: 10px;
  // border: 1px solid #DEDEDE;
  background: #fff;
  box-shadow: 0px 3px 6px #312f2f29;
  height: 100%;
  position: relative;
  top: 50px;
  .quiz-title {
    color: #212529d1;
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 400;
    padding-left: 10px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  .skill_image {
    float: left;
    img {
      height: 50px;
      width: 50px;
      border-radius: 3rem;
      margin-top: 6px;
    }
  }
  .pending_details_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: flex-start;
    padding: 14px;
    height: 150px;
    // width: 440px;
    // border: 1px solid #d1d0d0;
    // box-shadow: 0px 3px 6px #312f2f29;
    margin: auto;

    .slick-next:before {
      font-size: 21px;
      color: #00b27c;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .slick-prev:before {
      font-size: 21px;
      color: #00b27c;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .slick-prev {
      left: -22px;
      top: 61%;
    }
    .slick-next {
      right: -22px;
      top: 61%;
    }
  }
  .userpendingskillcard {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 13px;
    padding-top: 5px;
  }
  .progress {
    height: 0.45rem;
    margin-top: 9px;
    margin-left: 68px;
    width: 60%;
  }
  .viewSkillName {
    color: #646464;
    position: relative;
    font-size: 18px;
    margin-left: 71px;
    margin-top: 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .completion_ratio {
    float: right;
    margin-top: -16px;
    margin-right: -5px;
    font-size: 15px;
    font-weight: 600;
  }
  .slick-dots {
    bottom: -45px;
  }
}

/* outlook recently added skills*/
.out-look-recent-section {
  .lock-red {
    font-size: 30px;
    color: #fd3434c2 !important;
    margin-top: 4px;
    margin-right: 4px;
  }
  .lock-green {
    font-size: 30px;
    color: #28a745 !important;
    margin-top: 7px;
    margin-right: 6px;
  }

  .nuggets-title {
    font-size: 20px;
    color: #222;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  // .nugget-desc {
  // 	margin-left: 60px;
  // 	position: relative;
  // 	/* width: 210px;
  //     height: 115px;*/
  // 	overflow-wrap: break-word;
  // }
  // .viewed_by {
  //   margin-bottom: 10px;
  //  	  margin-top: -7px;
  // }
  .nuggets-text {
    color: #222;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  .new_flag {
    position: relative;
    float: right;
    margin-top: -18px;
    margin-right: -15px;
  }
  .nudge_image {
    width: 50px;
    height: 50px;
    float: left;
    margin-top: 3px;
  }
}

.custom-ui {
  padding: 0 15px;
}
