@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

fieldset, label { margin: 0; padding: 0; }
body{
  margin: 0px;
  height: 100%;
  width:  100%;
}
h1 { font-size: 1.5em; margin: 10px; }

/****** Style Star Rating Widget *****/

center{
  display: flex;
  justify-content: center;
}

.rating > input { display: none; }
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "FontAwesome", Arial, sans-serif;
  display: inline-block;
  content: "\f005";
  margin-left: -2px;
  margin-right: 4px;
  margin-top: -10px;
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
  margin-right: 10px;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  }

.rating-stars {
  font-size: 30px;
  text-align: center;
  margin: 0 auto;
}

.ratingHeight{
  height: 100%
}
.rating-label{
  float: left;
  margin: 6px 5px;
}

.Div_Border{
  background-color: #A6DBCB;
  text-align: center;
  font-size: 30px;
  margin-bottom:16px;
}

.new2 {
  border-top: 2px dashed #e8e8e8;
  width:190px;
  margin-top:0px;
}

.new1 {
  border-top: 2px dashed #e8e8e8;
}


.submit-btn-new{
  text-align:center;
}
.submit-btn-new .btn{
  height: 60px;
  width: 200px;
  border-radius: 40px;
  color: #fff;

}

.card-align{
  text-align:center;
  font-size: 25px;
  /* font-family: serif; */
}

.center_image {
  display: flex;
  justify-content: center;
}

.top-100px{
  top:100px;
}
.margin-bottom-12px{
  margin-bottom: 12px;
}

.pass_message{
  color: #6f6f6f;
  font-size: 20px;
  padding:3px;
  /* font-family: serif; */
}
.score{
  color: #6f6f6f;
  font-size: 38px;
}

.Div_Border_Sad{
  margin-bottom:16px;
  border-bottom: 1px solid #8080803b;
}

.middle-center{
  position: relative;
float: left;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
text-align: center;

color:
#6f6f6f;
font-size: 25px;
padding: 3px;
/* font-family: serif; */
}

.nextbtn-center{
  text-align: center;
  color:#6f6f6f;
}

.rating{
  width: 100%;
}

@media screen and (max-width: 580px) {
  .middle-center{
    font-size: 23px;
  }
  .pass_message{
    font-size: 17px;
  }
}