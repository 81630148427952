.app {
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow: scroll;
}

.container {
    margin-top: 03vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    box-shadow: 0 2px 2px #ccc;
    width: 45%;
    height: 115%;
}

.login-header {
    padding: 15px 0 0 0;
}

.login-form {
    width: 100%;
    padding: 10px;
    float: left;
}

.signUp-form {
    width: 100%;
    padding: 25px;
    float: left;
}

.login-footer {
    background-color: rgb(241, 241, 241);
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    height: 150px;
    float: left;
    border-top: 1px solid #ddd;
}

.login-app-name {
    margin-top: 10px;
    font-weight: 500;
    margin-bottom: 10px;
}

.login-footer-text {
    font-size: 13px;
    padding: 0 30px;
}

/** change start **/
.login-container input {
    outline: none;
    background-color: #fff !important;
    height: 30px;
    width: 80%;
    border: 0 none;
}

/** change end **/
.signUp-container input {
    outline: none;
    background-color: #fff !important;
    height: 26px;
    width: 80%;
    border: 0 none;
}

.login-container input:active, .login-container input:focus, .login-container input:visited, input:-internal-autofill-selected {
    background-color: #fff !important;
}

.signUp-container input:active, .signUp-container input:focus, .signUp-container input:visited, input:-internal-autofill-selected {
    background-color: #fff !important;
}

.signupbtndisplay {
    text-decoration: underline !important;
    margin-top: 10px;
}

button {
    border-radius: 4px;
    border-radius: 5px;
    background-color: #1a73e8;
    color: white;
    width: 100%;
    padding: 5px 5px;
}

button:hover {
    background-color: rgb(72, 97, 204);
    cursor: pointer;
}

.login-image {
    margin-bottom: 20px;
}

.harbinger-logo {
    width: 150px;
}

#error {
    color: #cc0033;
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    margin: 5px 0 0;
}

.login-container {
    padding: 0;
    background-color: #fff;
    border: 0 none;
    box-shadow: 0 0px 8px -1px #ccc;
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: block;
    flex-direction: inherit;
    width: 42%;
    height: 540px;
    cursor: inherit;
}

.signUp-container {
    padding: 0;
    background-color: #fff;
    border: 0 none;
    box-shadow: 0 0px 8px -1px #ccc;
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: block;
    flex-direction: inherit;
    width: 55%;
    height: 100%;
    cursor: inherit;
}

button.login-button {
    border-radius: 30px;
    background-color: #007bff;
    border: 1px solid #007bff;
    /*width: 23%;*/
    min-width: 100px;
    height: 48px;
}


/** change start**/
button.login-button:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

button.login-button:focus {
    outline: none;
}

/** change end***/

@media screen and (max-width: 768px) {
    div#root > div.app {
        height: 100%;
    }

    .login-container {
        width: 100%;
        margin: 0;
        height: 100%;
    }

    .signUp-container {
        width: 100%;
        margin: 0;
        /* height: 100%; */
        height: fit-content;
    }

    .login-container input {
        width: 80%;
        border-radius: 30px;
        margin-left: 0;

    }

/*    .login-container button.login-button{
        width: 23%;
    }*/

    .signUp-container input, button.sigUp-button {
        width: 80%;
        border-radius: 30px;
        margin-left: 0;
    }

    .login-footer {
        height: auto;
    }

    .middleHeaderText {
        text-align: left !important;
        left: 70px;
    }

    .dropDownCollapse {
        margin-top: 13%;
    }

    .dropdownButtonCollapse {
        width: 23%;
        margin-left: 80% !important;
    }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .login-container {
        width: 65%;
        height: 520px;
        margin-top: 7vh;
    }

    .login-footer {
        height: 140px;
    }

    .signUp-container {
        width: 65%;
        height: 520px;
        margin-top: 7vh;
    }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
    .login-container {
        width: 65%;
        height: 520px;
        margin-top: 7vh;
    }
}
.dot {
    background-color: #5ED800;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 0;
    float: right !important;
    color: #ffffff;
    padding: 1px 5px;
    text-align: center;
}

.logo-image {
    height: 36px;
    /*width: 165px;*/
}


.middleHeaderText {
    color: white;
    text-align: center;
    top: 20px;
    width: 100%;
    position: fixed;
    list-style-type: none;
    font-size: 16px;
}

.icon-wrapper {
    background: #045eb8;
    font-size: 26px;
    padding: 10px 12px 10px 20px;
    color: white;
    position: fixed;
    border-radius: 33px 5px 5px 33px;
    bottom: 44px;
    right: 10px;
    z-index: 5;

}

.icon-badge {
    top: -8px;
    left: -6px;
    font-size: 12px;
    position: absolute;
    background: #ff8000;
    border: 2px solid #fff;
    border-radius: 12px;
    padding: 2px 6px;
}

/** change start **/

.login-container .icon-seperator {
    border-right: 1px solid #ccc;
    margin: 0px 10px;
    padding: 5px 0px;
}

.login-container .icon {
    color: #ccc;
    font-size: 20px;
    vertical-align: middle;
}

.login-container .input-wrapper {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 26px;
    /*width: 300px;*/
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
}


.signUp-container .icon-seperator {
    border-right: 1px solid #ccc;
    margin: 0px 10px;
    padding: 5px 0px;
}

.signUp-container .icon {
    color: #ccc;
    font-size: 20px;
    vertical-align: middle;
}

.signUp-container .input-wrapper {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 26px;
    margin-left: auto;
    margin-right: auto;
    /*margin-bottom: 12px;*/
}


::placeholder {
    font-size: 12px;
}

/** change end **/

.login-form label {
    position: absolute;
    pointer-events: none;
    left: 23%;
    transition: 0.3s ease all;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.formField-link {
    color: #0056b3;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1.5px solid #0056b3;
    /*padding-bottom: 5px;*/
    margin-left: 4px;
    margin-right: 4px;

}

.Span {
    color: #fb6d6d;
    margin-bottom:12px;
    display: inline-block;
    font-size: 14px;
}

/*Confirm alert*/


body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
    font-size: 25px;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.custom-align{
    justify-content: center;
}

.logo-image img{
    height: 100%;
    width: 100%;
}

.custom-font{
    font-size: 16px;
}

.answer-label {
    color: #666;
    font-size: 16px;
    font-weight: normal;
    margin-left: 5px;
}

.correct-answer {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}