@import "../../../scss/partial/variable";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.slidersecondmain .slick-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.slidersecondmain .slick-list::-webkit-scrollbar:vertical {
  width: 5px;
}

.slidersecondmain .slick-list::-webkit-scrollbar:horizontal {
  height: 5px;
}

@-moz-document url-prefix() {
  .slidersecondmain .slick-list {
    scrollbar-width: thin;
  }
}

.react-player {
  width: 85% !important;
  height: 75% !important;
 // border: 2px solid #ffffff;
  @media screen and (max-width: $mobile-width) {
    width: 100% !important;
    height: 100% !important;
  }
}

.react-player-solid {
  width: 85% !important;
  height: 400px !important;
 // border: 2px solid #ffffff;

  @media screen and (max-width: $mobile-width) {
    width: 100% !important;
    height: 400px !important;
  }
}

.imageurldisplay {
  height: auto;
  width: 500px;
  right: auto;
  top: auto;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    top: unset;
    right: auto;
  }
}

.secondsliderwrapper {
  position: relative;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    height: 1000px;
    //  max-height: 560px;
    overflow: hidden;
    padding-top: 25px;
  }
}

.outerContent .progress {
  height: 5px;
  border-radius: 20px;
  max-width: 50%;
  margin: 0 auto;
  margin-top: -16px;
  margin-bottom: 20px;
}

.key-link a[href] {
  color: blue;
  text-align: center;
  margin-bottom: 20px;
}

.blackdot {
  color: red;
}

.evaluationslide {
  margin-top: -50px;
}

.secondslideonlyimage {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: stretch;
  width: 100%;

  .img-center {
    width: 85%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;

    @media screen and (max-width: $mobile-width) {
      width: 70%;
      padding: 10px 0px;
    }

    img {
      height: 75%;
      width: auto;
      max-height: 310px;
      border: 2px solid #ffffff;
    }
  }
}

.aftertipimage {
  width: 100%;
}

.secondSlidekey-text h1 {
  margin: 0;
  margin-bottom: 1rem;
}

.secondSlidekey-text ul {
  margin-left: 20px;
}

.background-img {
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 95%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.10196078431372549);
  background-position-y: center !important;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    max-height: fit-content;
  }
}

.background-img .inner-img {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  //transition: all 1s;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 1024px) {
  .background-img .inner-img {
    left: 50%;
    margin-left: -512px;
  }
}

.secondslidertxt-center {
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: $mobile-width) {
    font-size: 16px;
    font-weight: 500;
  }
}

.slidersecondmain {
  height: 100%;
  @media screen and (max-width: $mobile-width) {
    width: 100%;
    box-sizing: border-box;
    max-height: 438px;
  }

  .slick-list {
    overflow: hidden;
    height: 410px;
    width: 100%;
    transition: all 400ms ease;
    scroll-behavior: smooth;

    @media screen and (max-width: $mobile-width) {
      height: 100% !important;
      min-height: 100%;
      // overflow: hidden;
      //overflow-y: auto;
      // max-height: 500px;
    }
  }
}

.secondSlidername-last-flashcard {
  padding: 20px 0;
  font-size: 16px;
  margin: auto;
  text-align: center;
  color: black;

  p {
    text-align: center;
  }

  @media screen and (max-width: $mobile-width) {
    padding: 20px 0;
    font-size: 16px;
    margin: auto;
    text-align: center;
  }
}

.secondSlidekey-text {
  font-size: 18px;
  font-weight: 400;

  ol {
    margin-left: 20px;
  }
}

.secondSlideflashcard-last {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .img-last-flashcard,
  .name-last-flashcard {
    flex: 1;
  }
}

.onlytextdiv .secondSlidekey-text {
  display: flex;
  height: 100%;
  font-size: 20px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.textandimagecontainer {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: stretch;

  @media screen and (max-width: $mobile-width) {
    flex-direction: row;
    box-sizing: border-box;
    display: block;
    padding: 0 0px !important;
    align-items: stretch;
    // height: 600px;;
  }

  .secondSlidekey-text {
    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    vertical-align: middle;
    margin-top: 0;
    box-sizing: border-box;
    align-items: flex-start;
    text-align: left;

    p {
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 5px !important;
      }
    }

    ul {
      width: 91%;
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 5px !important;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      width: 100%;
      margin: 0;
      margin-bottom: 1rem;
      padding: 0 25px;

      @media screen and (max-width: $mobile-width) {
        padding: 0 5px !important;
        font-size: 1.5rem;
      }
    }

    ol {
      width: 100%;
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 5px !important;
      }
    }
    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: 100%;
      max-height: 200px;
      padding: 10px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .img-center {
    width: 50%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      max-height: 310px;
      border: 2px solid #ffffff;
      
    }

    @media screen and (max-width: $mobile-width) {
      width: 65%;
      display: block;
      height: auto;
      text-align: center;
      margin: 0 auto !important;
      float: none !important;
      padding: 10px !important;

      img {
        width: 80%;
        height: auto;
        border: 2px solid #ffffff;
        max-height: 310px;
      }
    }
  }
}

.showonlytext {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  width: 88%;
  margin: auto;
  display: flex;
  padding: 1.5rem 0;
  height: 460px;

  @media screen and (max-width: $mobile-width) {
    padding: 0;
    height: 100%;
    max-height: 410px;
    width: 100%;
  }

  .secondSlidekey-text {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 18px;
    font-weight: 400;

    p {
      padding: 0;
    }

    h1 {
      margin: 0;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: $mobile-width) {
      font-size: 17px;
      font-weight: 500;
    }
  }
}

.tip-image img {
  display: inline;
  width: 30px;
}

.hero-slider .slick-slider,
.hero-slider .slick-slider .slick-list,
.hero-slider .slick-slider .slick-track {
  height: 100%;
  min-height: 100%;
}

.hero-slider .slick-slider .slick-list {
  @media screen and (max-width: $mobile-width) {
    height: 510px !important;
    // min-height: 500px !important;
    // max-height: 500px !important;;
  }
}

// .hero-slider .slick-slider .slick-track {
//   @media screen and (max-width: $mobile-width) {
//     height: auto!important;
//   }
// }

.hero-slider .slick-initialized .slick-slide {
  display: flex;
  align-items: center;

  @media screen and (max-width: $mobile-width) {
    padding: 0px 1px;
    box-sizing: border-box;
  }
}

.hero-slider .slick-initialized .slick-slide>div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $mobile-width) {
    box-sizing: border-box;
    height: 740px;
  }

  // @media only screen and (max-width: 928px){
  // height: 900px;
  // }
}

.hero-slider .slick-initialized .slick-slide>div>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 380px) {
    height: 690px;
  }

  @media screen and (max-width: 928px) {
    height: 690px;
  }
}

.hero-slider .slick-initialized .slick-slide>div>div>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-slider .slick-initialized .slick-slide>div>div>div {
  @media screen and (max-width: $mobile-width) {
    height: 680px;
  }
}

@media only screen and (max-width: 915px) and (max-height: 450px) and (orientation: landscape) {
  .hero-slider .slick-initialized .slick-slide>div>div {
    height: 277px;
  }

  .hero-slider .slick-initialized .slick-slide>div>div>div {
    // background-color: #857dff;
    height: 288px;
  }

  .textandimagecontainer .secondSlidekey-text {
    flex: 0 0 62%;

    p {
      padding: 0px !important;
      margin: 0px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      padding: 0px !important;
      margin: 0px !important;
      font-size: 23px;
    }

    ul {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .secondSlidekey-text {
    padding: 0px !important;
    margin: 0px !important;
  }

  .secondsliderstart .key-link {
    width: 29% !important;
  }

  .imageurldisplay {
    height: 250px;
    width: 280px;
  }

  .textandimagecontainer .img-center {
    padding: 0px !important;

    img {
      width: 240px;
      height: 200px;
      border: 2px solid #ffffff;
    }
  }

  .hero-slider .slick-prev,
  .hero-slider .slick-next {
    display: none !important;
  }

  .evaluationIMG{
    height: 50px;
    width: 60px;
  }
  .secondslideonlyimage {
    & .img-center{
      img {
        height: 55%;
      }
    }
  }

  .secondSlidername-last-flashcard {
    padding: 0px !important;
  }

  .react-player-solid{
    height: 30% !important;
  }
}

.hero-slider .slick-initialized .slick-slide {
  display: flex;
  top: 10px;
  position: relative;
  align-items: center;

  @media screen and (max-width: $mobile-width) {
    top: -40px;
  }
}

.secondsliderstart {
  .key-link, .key-link-video {
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    margin-top: 0;
    box-sizing: border-box;
    max-height: 340px;
    min-height: 260px;
    align-items: center;
    //width: auto;
    // margin: 0 20px;
    // margin: 0 auto;
    img{
      border: 2px solid #ffffff;
      max-height: 310px;
    }
  }
}

.keyvideoandimage {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: $mobile-width) {
    display: block;
  }

  .keyImageMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    vertical-align: middle;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    width: 46%;
    margin: 0 20px;
    max-height: 340px;

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      // display: inline-block;
      // vertical-align: middle;
      margin: 0px;
    }
  }

  .key-link {
    max-height: 340px;
    //width: 46%;
    img{
      border: 2px solid #ffffff;
      max-height: 310px;
    }
    @media screen and (max-width: $mobile-width) {
      height: auto;
      width: 100%;
      img{
        border: 2px solid #ffffff;
        max-height: 310px;
      }
    }
  }
}

.key_linkisshow {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: stretch;
  align-items: center;
  margin: 0 30px;
  width: 100%;

  @media screen and (max-width: $mobile-width) {
    display: block;
    margin: 0px;
  }

  .secondSlidekey-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    vertical-align: middle;
    box-sizing: border-box;
    margin: 0 20px;
    min-height: 260px;
    align-items: flex-start;
    text-align: left;
    padding: 0 30px;
    // -webkit-backdrop-filter: blur(5px);
    // backdrop-filter: blur(5px);

    ul {
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 1px !important;
      }
    }

    p {
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 1px !important;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      margin-bottom: 1rem;
      padding: 0 25px;

      @media screen and (max-width: $mobile-width) {
        padding: 0 5px !important;
        font-size: 1.5rem;
      }
    }

    ol {
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;
    }

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .key-image {
    min-height: 250px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
    //backdrop-filter: blur(5px);

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .key-link {
    // -webkit-backdrop-filter: blur(4px);
    // backdrop-filter: blur(4px);
    img{
      border: 2px solid #ffffff;
      max-height: 310px;
    }
    @media screen and (max-width: $mobile-width) {
      height: auto;
      width: 100%;
    }

    .img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.key_text_video {
  flex-direction: row;
  box-sizing: border-box;
  place-content: stretchflex-start;
  align-items: stretch;
  display: flex;
  align-items: center;
  margin: 0 30px;
  width: 100%;

  @media screen and (max-width: $mobile-width) {
    display: block;
    margin: 0px;
  }

  .secondSlidekey-text {
    font-size: 20px;
    font-weight: 400;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    vertical-align: middle;
    margin-top: 0;
    box-sizing: border-box;
    align-items: flex-start;
    text-align: left;
    padding: 0px 30px;

    ul {
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 1px !important;
        font-size: 17px;
      }
    }

    p {
      padding: 0 30px !important;

      @media screen and (max-width: $mobile-width) {
        padding: 0 1px !important;
        font-size: 17px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      margin-bottom: 1rem;
      padding: 0 25px;
      @media screen and (max-width: $mobile-width) {
        padding: 0px !important;
        font-size: 1.5rem;
      }
    }

    ol {
      margin-left: 20px;
      text-align: left;
      padding: 0 30px !important;
      @media screen and (max-width: $mobile-width) {
        padding:  0px !important;
      }
    }

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .key-link {
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    margin-top: 0;
    box-sizing: border-box;
    min-height: 260px;
    align-items: center;
    width: 50%;
    max-height: 340px;
    img{
      border: 2px solid #ffffff;
      max-height: 310px;
    }
    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
}

.key_link_Image {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  max-height: 85%;

  @media screen and (max-width: $mobile-width) {
    display: block;
  }

  .secondSlidekey-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    vertical-align: middle;
    margin-top: 0;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    padding: 0px 21px;
    width: 40%;

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .key-image {
    height: 460px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;

    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .key-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 50%;
    img{
      border: 2px solid #ffffff;
      max-height: 310px;
    }
    @media screen and (max-width: $mobile-width) {
      height: auto;
      width: 100%;
    }
  }

  .displayImage {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 50%;

    .image_box {
      width: 50%;
      img{
        border: 2px solid #ffffff;
        max-height: 310px;
      }
      //border: 5px solid;
      @media screen and (max-width: $mobile-width) {
        height: auto;
        width: 100%;
      }

      .allview-img {
        width: 100%;
        // border: solid;
      }
    }
  }
}

.blockchaintext .secondslidertxt-center {
  display: flex;
  height: 100%;
  font-size: 20x;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  width: 88%;
  margin: auto;
}

.tipImageContainer {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretchflex-start;
  align-items: stretch;

  .tip-image {
    position: absolute;
    left: 0;
    top: -10px;
  }

  .secondSlidekey-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 50px;
    font-size: 20px;
    font-weight: 400;
    width: 33%;
    float: left;
    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .img-center {
    @media screen and (max-width: $mobile-width) {
      width: 65%;
      display: block;
      height: 80%;
      padding: 20px 0;
      text-align: center;
    }
  }
}

.secontipImg {
  .tip-image {
    position: absolute;
    left: 0;
    top: -10px;
  }

  .secondSlidekey-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 50px;
    font-size: 20px;
    font-weight: 400;
    width: 50%;
    float: left;
    @media screen and (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      padding: 20px 0px;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.blockchaintext p {
  padding: 0;
}

/*@media screen and (max-width: 576px) start*/

@media screen and (max-width: 576px) {
  .hero-slider .magic-dots.slick-dots ul {
    margin: 0 auto;
  }
}

.imgwithurl {
  .img-center {
    padding: 30px;
   // backdrop-filter: blur(5px);
    border-radius: 18px;
    max-height: 500px;

    @media screen and (max-width: $mobile-width) {
      width: 285px;
      display: flex;
      height: 295px;
      padding: 20px 0;
      justify-content: center;
    }
  }
}

.key_link_Image {
  .displayImage {
    @media screen and (max-width: $mobile-width) {
      width: 100%;
      display: block;
      height: auto;
      padding: 20px 0;
      text-align: center;
    }
  }
}

.secondSlidekey-text,
.textandimagecontainer .secondSlidekey-text {
  align-items: flex-start;
  text-align: left;
  padding: 0 30px;
 // backdrop-filter: blur(5px);
  padding: 20px;
  margin: 0 20px;
  border-radius: 18px;

  @media screen and (max-width: $mobile-width) {
    padding: 5px !important;
    width: 500px;
    text-align: justify;
  }
}

.textandimagecontainer {
  padding: 0 30px;
  width: 100%;

  .img-center {
    padding: 10px 30px;
    //backdrop-filter: blur(5px);
    margin: 0 20px;
    img {
      border: 2px solid #ffffff;
      max-height: 310px;
    }
  }
}

.mainSliderBlock {
  padding: 20px 0 !important;
  padding-top: 30px !important;
}

.buttonBlock {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
}

.backBtnBlock {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 25px;
}

.backBtnBlock button {
  width: 42px;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  outline: none;
  color: white;
  font-weight: 500;
}

.backBtnBlock i.fa-angle-left {
  font-size: 20px;
  color: white;
  padding: 0px 0 0 10px;
  line-height: 0;
  margin-left: 20px;
  margin-right: 5px;
}

.stepperBlock {
  margin-top: 28px;
  margin-left: 20px;
  flex: 15;
}
.fullscreenBlock {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.fullscreenBlock button {
  width: 42px;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  outline: none;
  color: white;
  font-weight: 500;
}
.newspakerIcon button#voiceButton {
  box-shadow: 0px 1px 2px #666666 !important;
  background: #000000 !important;
  height: 26px;
  width: 26px;
  margin: 6px 20px 0 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newspakerIcon button i#voiceIcon {
  font-size: 20px;
  color: #857dff;
}

.showonlytextwrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.expandBtnBlock {
  flex: 1;
}

.expandBtnBlock button {
  background: transparent;
  border: 0;
  font-size: 15px;
  width: 50px;
  color: #2c428d;
}

.expandBtnBlock button i {
  font-size: 18px;
}

.textandimagecontainer .img-center,
.textandimagecontainer .secondSlidekey-text,
.showonlytext .secondSlidekey-text,
.flashcard-last {
  flex-direction: column;
  justify-content: center;
}

/*slider css*/

.buzzes {
  overflow: auto;
  margin-bottom: 0.65rem;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: rgb(12, 12, 12);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $tablet-width) {
    overflow: auto;
    margin-top: 5px;
    margin-bottom: 0.65rem;
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #0c0c0c;
  }
}

.quiz-buzzes {
  overflow: auto;
  margin-top: -7px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: rgb(12, 12, 12);

  @media screen and (max-width: $tablet-width) {
    overflow: auto;
    margin-top: 0;
    margin-bottom: 0.65rem;
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #0c0c0c;
  }
}

.page-head {
 // width: 21%;
  display: inline-block;

  @media screen and (max-width: $tablet-width) {
    width: 250px;
  }
}

.name-last-flashcard {
  padding: 0px 20px;
  font-size: 18px;
  margin: 15px auto;
}

.breadMain {
  a {
    display: inline;
  }

  span {
    font-size: 16px;
    color: #546e7a;
    margin-left: 5px;
  }
}

.flashcard-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  color: black;
  .img-last-flashcard,
  .name-last-flashcard {
    flex: 1;
  }
}

.showkeytextmain {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  width: 88%;
  margin: auto;
  display: flex;
  padding: 1.5rem 0;
  height: 400px;

  .key-text {
    width: 100%;
    margin: 0;
    padding: 0 !important;
  }
}

.txt-center {
  display: flex;
  height: 100%;
  font-size: 20px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  img {
    height: auto;
    width: 100%;
    border: 2px solid #ffffff;
    max-height: 310px;
    @media screen and (max-width: $mobile-width) {
      width: 75%;
    }
  }
}

.key-link {
 // backdrop-filter: blur(4px);
  border-radius: 18px;

  a[href] {
    color: blue;
  }

  a:hover {
    color: purple;
  }

  text-decoration: underline;
}

.key-text {
  text-align: left;
  margin-top: 19px;
  padding: 0 21px 0 20px;
  font-size: 18px;
  font-weight: 400;

  ol {
    margin-left: 20px;
  }
}

p {
  margin-bottom: 5px;
  padding: 0px;
}

.evaluationIMG {
  max-width: 109px;
  width: auto;

  @media screen and (max-width: $mobile-width) {
    width: auto;
    max-width: 150px;
    margin-bottom: 5px;
    height: auto;
  }
}

.submit-btn {
  margin: 10px 0;
}

.slick-slide img {
  display: inline;
}

.sliderstart .key-link {
  height: 260px;
  max-height: 260px;
  margin-top: 25px;
}

.sliderstart .slick-next {
  font-size: 0;
  line-height: 0;
  top: 50%;
  right: -8px;
  width: 52px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 2;

  @media screen and (max-width: $mobile-width) {
    top: 50%;
    right: -20px;
  }
}

.sliderstart .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  left: -8px;
  width: 52px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 2;

  @media screen and (max-width: $mobile-width) {
    top: 50%;
    left: -20px;
  }
}

.key-image {
  width: 250px;
  margin: auto;
}

.key-image a img {
  max-width: 100%;
//  max-height: 100%;
  display: block;
  max-height: 310px;
}

.sliderstart .key-text {
  text-align: left;
  margin-top: 19px;
  padding: 0 21px 0 20px;
  font-size: 18px;
  font-weight: 400;

  ol {
    margin-left: 10%;
  }

  ul {
    margin-left: 10%;
  }

  h1 {
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 5px;
    padding: 0px 21px 0px 21px;
  }
}

/*second slider component css*/

.background-img .inner-img {
  width: 100%;
  height: 100%;
  background-position-y: center !important;
  transition: all 1s;
}

.hero-slider {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
}

.hero-slider .slick-prev,
.hero-slider .slick-next {
  top: 50%;
  display: grid;
  width: 60px !important;
  background: rgba(0, 0, 0, 0.81);
  height: 45px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: #000;
  z-index: 1;

  @media screen and (max-width: $mobile-width) {
    display: none !important;
  }
}

.hero-slider .slick-next:before {
  content: "\f105";
  font: 40px/1 "FontAwesome", Arial, sans-serif;
  font-size: 40px;
  color: #ffffff;
  font-weight: 400;
  padding-right: 15px;

  @media screen and (max-width: $mobile-width) {
    display: none !important;
  }
}

.hero-slider .slick-prev:before {
  content: "\f104";
  font: 40px/1 "FontAwesome", Arial, sans-serif;
  color: #ffffff;
  font-size: 40px;
  font-weight: 400;
  padding-left: 15px;

  @media screen and (max-width: $mobile-width) {
    display: none !important;
  }
}

.secondSlidekey-text {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  ol {
    margin-left: 20px;
  }
}

.hero-slider .slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #6d6d6d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: $mobile-width) {
    padding: 8px 0px 8px;
    bottom: 0;
    top: -5px;
  }
}

.hero-slider .magic-dots.slick-dots li.slick-active button:before {
  color: #6d6d6d;
  opacity: 1;
}

.onlytextdiv .secondSlidekey-text {
  display: flex;
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.theme_Preview {
  text-align: center;
  height: 100%;
  width: 40px;
}

.themePreview {
  text-align: center;
  height: 40px;
  width: 40px;
}

.themePreview img {
  width: 100%;
  height: 100%;
}

.custom_model {
  top: 15%;
  right: 0;

  .modal-content {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: 0.5;
    width: 17px;
    padding: 0;
    margin: 0;
    z-index: 1111;
  }

  .modal-header {
    border: none;
  }

  .modalThemeImg {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.header-wrapper .headerBlock .logo-wrapperimg {
  @media screen and (max-width: 500px) {
    max-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.header-wrapper .logo-wrapper .menu-icon {
  @media screen and (max-width: 500px) {

    align-items: center;
    justify-content: center;
    display: flex;

  }
}

.custom_model_body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.textandimagecontainer .img-center {
  width: 50%;
  float: right;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 30px;

    img {
      width: 260px;
      height: 160px;
      border: 2px solid #ffffff;
      max-height: 310px;
    }
  }
}

.margin-reduce {
  margin-top: 62px;
  height: 100vh;

  .breadcrumb,
  .outerContent {
    display: none;
  }

  .background-img,
  .hero-slider {
    width: 100%;
    max-height: 100%;
  }

  .secondsliderwrapper {
    max-height: 100%;
  }

  .showonlytext {
    height: 100%;
  }

  .slidersecondmain {
    max-height: 100%;
  }

  .secondsliderstart {
    //height: calc(100vh - 130px);
  }

  div#views {
    height: 100vh;
  }

  .showonlytext {
    padding-top: 50px;
  }

  button.backBtn {
    position: absolute;
    width: 100px;
    background: transparent;
    border: 0;
    color: #0b6eb6;
    font-size: 17px;
    font-weight: 600;
  }
}

.secondsliderstart {
  //height: calc(100vh - 188px);
}

.new_slider {
  button.backBtn {
    position: absolute;
    width: 100px;
    background: transparent;
    border: 0;
    color: #0b6eb6;
    font-size: 17px;
    font-weight: 600;
  }

  button.sound_btn {
    width: 35px;
    background: black;
    border-radius: 50%;
    border: 0;
    position: absolute;
    right: 11px;
    top: 5px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00b27c;
  }
}

.outerContent {
  display: none;
}

.secondsliderstart.sliderTimline {
  .stepper {
    height: 104px;
    position: fixed;
    bottom: 0%;
    width: 100%;
    opacity: 1;
    display: flex;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
  }

  .backButton {
    position: absolute;
    top: 0;
    height: 20%;
    z-index: 123;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0.18) 52%, rgba(0, 0, 0, 0.07) 78%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);

    @media screen and (max-width: $mobile-width) {
      height: 100px;
    }
  }

  .hero-slider {
    width: 100%;
  }

  .background-img {
    width: 100%;
  }
}

.mainSliderBlock {
  width: 100%;
  height: 100%;
  min-height: 100%;
  color: #fff;
  opacity: 0.8;
  padding: 10px 20px;
  background-size: cover;
  position: relative;
  .timeline {
    max-width: 995px;
    margin: 0 auto;
    height: 100px;
  }

  li {
    background-image: none !important;
  }

  .txtImgBlock {
    padding: 0 40px;
    max-width: 995px;
    margin: 0 auto;
    display: flex;
  }

  .txtBlock {
    background: rgba(26, 63, 139, 0.5);
   // backdrop-filter: blur(5px);
    margin-right: 20px;
    padding: 10px;
    flex: 1;

    img {
      max-width: 400px;
      margin: 0 auto;
      display: flex;
      max-height: 310px;
    }
  }

  .imgBlock {
    // background: rgba(26, 63, 139, 0.5);
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    // border-radius: 0;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px;
    flex: 1;
  }

  .magic-dots {
    display: none;
  }
}

.visibility {
  visibility: hidden;
}

.speakerIcon {
  display: "inline-block";
 // width: 7%;
  float: right;
  margin-top: 65px;
 // margin-right: 20px;
  @media screen and (max-width: 926px) {
    margin-right: 25px;
  }
}

.allContent {
  overflow-y: scroll;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.allContent_height {
  overflow-y: hidden;
  @media screen and (max-width: $mobile-width) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.stepperLable {
  font-size: 15px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 25px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  overflow-y: scroll;
}

.div-wrapper {
  flex-basis: 50%;
  min-height: 100px;
}

.cancelBtnBlock {
  flex: 1;
  // display: flex;
  // align-items: center;
  margin-top: 10px;
  text-align: center;
}

.cancelBtnBlock button {
  //width: 42px;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  outline: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
}

.nextPlay {
  height: 80px;
  width: 80px;
  display: inline-block;
}

// @media (min-width: 620px) and (max-width: 820px) {
//   .slick-slider {
//     & .secondSlidekey-text {
//       display: block;
//       flex: 0 0 50%;
//     }
//   }
//  }

@media (min-width: 320px) and (max-width: 570px) {
  // .slick-slider {
  //   & .slick-active.slick-current {
  //     & div:nth-child(1) {
  //     //  height: 100%;
  //        min-height: 00px;
  //    //    max-height: 600px;
  //     //  overflow-x: hidden;
  //     //  overflow-y: scroll;
  //       align-items: center;
  //     }
  //   }
  // }
}
@media screen and (max-width: $tablet-width) {
  .textandimagecontainer{
    display: block;
    padding: 0 5px;
    & .secondSlidekey-text{
      width: 100%;
    }
    & .img-center{
      width: 100%;
    }
  }
}
.img {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: black;
  -webkit-mask: url("../white-play-icon-png-6.png") center/contain;
  mask: url("../white-play-icon-png-6.png") center/contain;
}

// img {
//   width:40px;
//   height:40px;
// }
