$main-bg-color: #2369e0; 
$heading-color: #fff; 
$green-color:#1cac5a;
$heading-font-size:20px;
$heading-font-mob-size:16px;
$font-color:#37474F;
$font-light-color:#546E7A;
$font-ex-light-color:#ced4da;
$border-bottom:1px solid #e6e6e6;
// media query
$width-name: max-device-width;
$mobile-width: 576px;
$tablet-width: 768px;
$midum-width: 990px;
$desktop-width: 1024px;